export default {
  NameError() {
    const field = this.$v.actionPlan.Name;
    return _validateField(field);
  },

  TimeAfterPreviousStepError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].TimeAfterPreviousStep;
    return _validateField(field);
  },

  PeriodAfterPreviousStepError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].PeriodAfterPreviousStep;
    return _validateField(field);
  },


  StepTaskNameError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].TaskName;
    return _validateField(field);
  },

  StepTaskCategoryError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].TaskCategory;
    return _validateField(field);
  },


  StepEmailCategoryError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].EmailCategory;
    return _validateField(field);
  },

  StepEmailEnglishTemplateError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].EmailEnglishTemplate;
    return _validateField(field);
  },

  StepEmailSpanishTemplateError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].EmailSpanishTemplate;
    return _validateField(field);
  },

  StepEmailFromError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].EmailFrom;
    return _validateField(field);
  },


  StepSmsEnglishMessageError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].SmsEnglishMessage;
    return _validateField(field);
  },

  StepSmsSpanishMessageError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].SmsSpanishMessage;
    return _validateField(field);
  },

  StepSmsFromError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].SmsFrom;
    return _validateField(field);
  },


  StepNoteMessageError(index) {
    const field = this.$v.actionPlan.Steps.$each.$iter[index].NoteMessage;
    return _validateField(field);
  },
};

function _validateField(field) {
  if (!field.$anyError) return null;
  else if (!field.required) return "required_field";
  else if (field.$invalid) return "invalid_field";
  else return null;
}