export const getStepActions = () => [
  {
    label: 'Create Task',
    value: 'task',
  }, {
    label: 'Send Email',
    value: 'email',
  }, {
    label: 'Send SMS',
    value: 'sms',
  }, {
    label: 'Add Note',
    value: 'note',
  },
];

export const getStepActionFilters = action => {
  const actions = {
    task: [
      {
        Name: 'TaskName',
        Label: 'Task Name',
      }, {
        Name: 'TaskCategory',
        Label: 'Task Category',
        isComboBox: true,
        isExternal: false,
        Options: [
          {label: 'To-do', value: 'To-do'},
          {label: 'Email', value: 'Email'},
          {label: 'Sms', value: 'Sms'},
          {label: 'Call', value: 'Call'},
          {label: 'Feedback', value: 'Feedback'},
        ]
      }, {
        Name: 'TaskDescription',
        Label: 'Description',
        isTextArea: true,
      },
    ],

    email: [
      {
        Name: 'EmailCategory',
        Label: 'Email Category',
        isComboBox: true,
        isExternal: true,
        Options: [],
      }, {
        Name: 'EmailEnglishTemplate',
        Label: 'English Email Template',
        isComboBox: true,
        isExternal: true,
        Options: [],
      }, {
        Name: 'EmailSpanishTemplate',
        Label: 'Spanish Email Template',
        isComboBox: true,
        isExternal: true,
        Options: [],
      }, {
        Name: 'EmailFrom',
        Label: 'From',
        isComboBox: true,
        isExternal: true,
        Options: [],
      },
    ],

    sms: [
      {
        Name: 'SmsEnglishMessage',
        Label: 'English Message',
        isTextArea: true,
      }, {
        Name: 'SmsSpanishMessage',
        Label: 'Spanish Message',
        isTextArea: true,
      }, {
        Name: 'SmsFrom',
        Label: 'From',
        isComboBox: true,
        isExternal: true,
        Options: [],
      },
    ],

    note: [
      {
        Name: 'NoteMessage',
        Label: 'Message',
        isTextArea: true,
      },
    ],
  }

  if (!actions[action]) {
    throw 'Valid action is required';

  } else {
    return actions[action];
  }
};