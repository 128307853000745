import Draggable from 'vuedraggable';

import Utils from '@/helpers/utils';
import Dh from "@/helpers/date.helper";
import {getStepActions, getStepActionFilters} from './helpers';

import DeleteModal from "../Modals/DeleteModal.vue";

import validations from './validations';
import errors from './errors';

import HttpClient from "@/helpers/http.client";
import ActionPlanService from "@/services/action.plan.service";
import TwilioNumberService from "@/services/twilio.number.service";
import MailTemplateService from '@/services/mail.template.service';
import MailService from '@/services/mail.service';

const http = new HttpClient()
    , service = new ActionPlanService(http)
    , twilioNumberService = new TwilioNumberService(http)
    , mailTemplateService = new MailTemplateService(http)
    , mailService = new MailService(http);

export default {
  name: "ActionPlansDetails",

  components: {
    Draggable,
    DeleteModal
  },

  data() {
    return {
      isLoading: false,
      showDeleteModal: false,

      actionPlan: {
        Name: '',
        PauseWhenLeadResponds: true,
        Steps: [{
          TimeAfterPreviousStep: 0,
          PeriodAfterPreviousStep: 'days',
          Action: 'task',

          TaskName: '',
          TaskCategory: '',
          TaskDescription: '',

          EmailCategory: null,
          EmailEnglishTemplate: null,
          EmailSpanishTemplate: null,
          EmailFrom: '',

          SmsEnglishMessage: '',
          SmsSpanishMessage: '',
          SmsFrom: '',

          NoteMessage: '',

          isHidden: false,
        }],
      },

      currentStep: {
        TimeAfterPreviousStep: 0,
        PeriodAfterPreviousStep: 'days',
        isHidden: false,

        Action: '',
        TaskName: '',
        TaskCategory: '',
        TaskDescription: ''
      },

      actions: [...getStepActions()],
      myNumbers: [],
      myEmails: [],
      mailCategories: [],
      mailTemplates: [],

      times: [
        {value: 'days', label: this.$t('days')},
        {value: 'hours', label: this.$t('hours')},
        {value: 'minutes', label: this.$t('minutes')},
      ],
    }
  },

  validations: validations,

  computed: {
    isCreateMode() {
      return this.$route.name === 'ActionPlansCreate';
    },

    isEditMode() {
      return this.$route.name === 'ActionPlansDetails';
    },

    dragOptions() {
      return {
        animation: 400,
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true
      };
    },

    createdDescription() {
      const createdAtDay = Dh.customFormat(this.actionPlan.createdAt, 'MMM Do, YYYY'),
          createdAtHour = Dh.customFormat(this.actionPlan.createdAt, 'h:mma');

      if (this.actionPlan.Agent) {
        const agentName = `${this.actionPlan.Agent.FirstName} ${this.actionPlan.Agent.LastName}`;
        return `Created on ${createdAtDay} at ${createdAtHour} by ${agentName}`
      }

      return `Created on ${createdAtDay} at ${createdAtHour} by Ezleads Automations`;
    }
  },

  async mounted() {
    await Promise.allSettled([
      this.getMyNumbers(), this.getMyEmails(), this.getMailCategories()
    ]);

    if (this.isEditMode) {
      await this.getActionPlan();
    }
  },

  methods: {
    ...errors,

    async createActionPlan() {
      this.$v.$reset();
      await this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      const res = await service.create(this.actionPlan);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('create_success')});
        await this.$router.push({name: 'ActionPlansList'});

      } else {
        this.$notify({type: 'error', message: this.$t('create_error')});
      }

      this.isLoading = false;
    },

    async getActionPlan() {
      const actionPlanId = this.$route.params.id;

      const res = await service.get(actionPlanId);
      if (res && !res.error) {
        this.actionPlan = res.data.actionPlan;
      }
    },

    async editActionPlan() {
      this.$v.$reset();
      await this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      const res = await service.edit(this.actionPlan);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('edit_success')});
        await this.$router.push({name: 'ActionPlansList'});

      } else {
        this.$notify({type: 'error', message: this.$t('edit_error')});
      }

      this.isLoading = false;
    },

    async addStep() {
      const isFirstStep = this.actionPlan.Steps.length <= 0;

      if (!isFirstStep) {
        await this.$v.actionPlan.Steps.$touch();
        if (this.$v.actionPlan.Steps.$invalid) {
          return;
        }
      }

      const step = {
        TimeAfterPreviousStep: this.actionPlan.Steps.length > 0 ? 1 : 0,
        PeriodAfterPreviousStep: 'days',
        Action: 'task',
        isHidden: false,
      }

      this.actionPlan.Steps.forEach(el => el.isHidden = true);
      this.actionPlan.Steps.push(step);

      this.$nextTick(() => {
        const actionPlan = this.$refs.actionplan;
        actionPlan.scroll({top: actionPlan.scrollHeight});
      });
    },

    removeStep(index) {
      this.actionPlan.Steps.splice(index, 1);
    },

    toggleEditStep(step) {
      step.isHidden = !step.isHidden;
    },

    stepDayNumberDescription(step) {
      if (step.TimeAfterPreviousStep > 0) {
        const period = step.TimeAfterPreviousStep > 1 ? step.PeriodAfterPreviousStep : step.PeriodAfterPreviousStep.slice(0, -1);
        return `${step.TimeAfterPreviousStep} ${period}`;
      }

      return this.$t('immediately');
    },

    stepRunDescription(step) {
      if (step.isHidden) {
        /*const actions = {
          task: `${step.}`
        };*/

        return this.$t('run');
      }

      return this.$t('run');
    },

    stepActionFilters(action) {
      return [...getStepActionFilters(action)];
    },

    async getMyNumbers() {
      const res = await twilioNumberService.list(100, 0, {types: 'ACTION_PLAN'});
      if (res && !res.error) {
        this.myNumbers = res.data.numbers.map(number => ({
          name: `${number.friendlyName} +1 ${Utils.formatUSNumber(number.phoneNumber)}`,
          phone: number.phoneNumber,
        }));
      }
    },

    async getMyEmails() {
      const res = await mailService.list();
      if (res && !res.error) {
        this.myEmails = res.data.accounts;
      }
    },

    async getMailCategories() {
      const res = await mailTemplateService.listCategories();
      if (res && !res.error) {
        this.mailCategories = res.data;
      }
    },

    async getMailTemplates(category) {
      const res = await mailTemplateService.list(category);
      if (res && !res.error) {
        this.mailTemplates = res.data.templates;
      }
    },

    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
  },
};