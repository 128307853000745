import {minLength, required, requiredIf, minValue, numeric} from "vuelidate/lib/validators";

export default {
  actionPlan: {
    Name: {
      required
    },
    Steps: {
      required,
      minLength: minLength(1),

      $each: {
        TimeAfterPreviousStep: {
          required,
          numeric,
          minValue: minValue(0)
        },
        PeriodAfterPreviousStep: {
          required,
        },
        Action: {
          required,
        },

        TaskName: {
          required: requiredIf(step => step.Action === 'task')
        },
        TaskCategory: {
          required: requiredIf(step => step.Action === 'task')
        },
        TaskDescription: {
          //required: requiredIf(step => step.Action === 'task')
        },

        EmailCategory: {
          required: requiredIf(step => step.Action === 'email')
        },
        EmailEnglishTemplate: {
          required: requiredIf(step => step.Action === 'email')
        },
        EmailSpanishTemplate: {
          //required: requiredIf(step => step.Action === 'email')
        },
        EmailFrom: {
          required: requiredIf(step => step.Action === 'email')
        },

        SmsEnglishMessage: {
          required: requiredIf(step => step.Action === 'sms')
        },
        SmsSpanishMessage: {
          //required: requiredIf(step => step.Action === 'sms')
        },
        SmsFrom: {
          required: requiredIf(step => step.Action === 'sms')
        },

        NoteMessage: {
          required: requiredIf(step => step.Action === 'note')
        },
      }
    },
  },
}